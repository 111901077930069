import anime from 'animejs/lib/anime.es.js';


export function showDetails(details, detailsId) {
    let showT = anime.timeline({
            autoplay: false
        })
        .add({
            targets: "#" + detailsId + ' .temDetails__Close',
            opacity: [0, 1],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)'
        })
        .add({
            targets: "#" + detailsId + " .temDetails__item",
            opacity: [0, 1],
            translateY: [20, 0],
            duration: 500,
            delay: anime.stagger(100),
            easing: 'cubicBezier(.215,.61,.355,1)'
        }, "-=200");
    return showT;
}

export function hideDetails(details, detailsId) {
    let hideT = anime.timeline({
            autoplay: false
        })
        .add({
            targets: "#" + detailsId + ' .temDetails__Close',
            opacity: [1, 0],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)'
        })
        .add({
            targets: "#" + detailsId + " .temDetails__item",
            opacity: [1, 0],
            translateY: [0, 20],
            duration: 500,
            delay: anime.stagger(100),
            easing: 'cubicBezier(.215,.61,.355,1)'
        }, "-=200");
    return hideT;
}
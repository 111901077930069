import anime from 'animejs/lib/anime.es.js';

export function initFixedNav(fixedNav) {
    window.addEventListener('scroll', function(e) {
        if ($(".js-menu").hasClass("opened")) return false;
        if ($(window).scrollTop() > 62) {
            fixedNav.addClass("visible");
        } else {
            fixedNav.removeClass("visible");
        }
    });
}

export function initOpenNavAnim() {

    let openNavTl = anime.timeline({
        autoplay: false,
        complete: function(anim) {
            $("#menu").trigger('navOpened');
            $("#menu").addClass("opened");
        }
    });
    openNavTl.add({
            targets: '.js-menu',
            translateX: ['100%', '0'],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)'
        })
        .add({
            targets: '.js-menu__nav__link',
            translateY: ['150%', '0'],
            opacity: [0, 1],
            duration: 450,
            delay: anime.stagger(80),
            easing: 'cubicBezier(.215,.61,.355,1)'
        })
        .add({
            targets: '.js-closeNav',
            opacity: [0, 1],
            duration: 400,
            easing: 'cubicBezier(.215,.61,.355,1)'
        }, "-=200");

    return openNavTl;
}

export function initCloseNavAnim() {
    let closeNavTl = anime.timeline({
        autoplay: false,
        complete: function(anim) {
            $("#menu").trigger('navClosed');
            $("#menu").removeClass("opened");
        }
    });
    return closeNavTl;
}

export function buildCloseNavAnim(closeNavTl) {
    closeNavTl.add({
            targets: '.js-closeNav',
            opacity: [1, 0],
            duration: 400,
            easing: 'cubicBezier(.215,.61,.355,1)'
        })
        .add({
            targets: '.js-menu__nav__link',
            translateY: ['0', '150%'],
            opacity: [1, 0],
            duration: 450,
            delay: anime.stagger(80),
            easing: 'cubicBezier(.215,.61,.355,1)'
        }, "-=100")
        .add({
            targets: '.js-menu',
            translateX: ['0', '100%'],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)'
        }, "-=250");

    $("#menu").addClass("closeBuilt");
    return closeNavTl;

}


export function goToSection(section, navTimelineClose) {
    let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
    let eTop = section.offset().top - 100;
    let sD = eTop * 0.8;
    if (eTop > 1000) sD = eTop * 0.6;

    navTimelineClose.play();
    navTimelineClose.finished.then(function() {
        anime({
            targets: scrollElement,
            scrollTop: eTop,
            duration: sD,
            easing: 'cubicBezier(.215,.61,.355,1)'
        });
    });
}


export function goToSectionAlone(section) {
    let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
    let eTop = section.offset().top; - 100;
    let sD = eTop * 0.8;
    if (eTop > 1000) sD = eTop * 0.6;
    anime({
        targets: scrollElement,
        scrollTop: eTop,
        duration: sD,
        easing: 'cubicBezier(.215,.61,.355,1)'
    });
}


export function hideNavOnClickOutside(selector, timeline) {
    const outsideClickListener = (event) => {
        const $target = $(event.target);
        if (!$target.closest(selector).length && $(selector).hasClass('opened')) {
            timeline.play();
            //removeClickListener();
        }
    }

    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener)
    }

    document.addEventListener('click', outsideClickListener)
}
import "es6-shim";
import 'nodelist-foreach-polyfill';
import "scrollingelement";
import device from "current-device";
import anime from 'animejs/lib/anime.es.js';
import overlayScrollbars from 'overlayScrollbars/js/jquery.overlayScrollbars.min.js';
import objectFitImages from 'object-fit-images';
import Splide from '@splidejs/splide';

import modularScroll from 'modularscroll';


import * as Nav from './_nav.js';
import * as Comp from './_competences.js';
import * as Tem from './_temoignages.js';


jQuery(document).ready(function($) {

    var ieVer = ieVersion();
    if (ieVer > 0) {
        $("html").addClass("ie ie-" + ieVer);
    }

    var scroll = new modularScroll();



    /*****************************/
    /*****************************/
    // NAV
    Nav.initFixedNav($(".js-fixedNavBar"));

    var navTimelineOpen = Nav.initOpenNavAnim();
    var navTimelineClose = Nav.initCloseNavAnim();

    $(".js-openNav").on('click', function(e) {
        e.stopImmediatePropagation();
        let tThis = $(this);
        navTimelineOpen.play();
        return false;
    });

    $("#menu").on('navOpened', function() {
        if (!$("#menu").hasClass("closeBuilt"))
            Nav.buildCloseNavAnim(navTimelineClose);

        // ARIA
        $(".js-menu").attr('aria-hidden', false);
        $(".js-openNav").each(function() {
            $(this).attr('aria-expanded', true);
        });
    });

    $("#menu").on('navClosed', function() {
        $(".js-menu").attr('aria-hidden', true);
        $(".js-openNav").each(function() {
            $(this).attr('aria-expanded', false);
        });
    });

    $(".js-closeNav").on('click', function(e) {
        e.stopImmediatePropagation();
        if (!$('#menu').hasClass('opened')) return false;
        navTimelineClose.play();
        return false;
    });

    Nav.hideNavOnClickOutside($("#menu"), navTimelineClose);

    $(".menu__nav__wrapper").overlayScrollbars({ className: "os-theme-thin-light os-magali", paddingAbsolute: true });


    $(".js-menu__nav__link").on('click', function(e) {
        e.stopImmediatePropagation();
        let tThis = $(this);
        if (!$("#menu").hasClass("closeBuilt"))
            Nav.buildCloseNavAnim(navTimelineClose);

        if (tThis.hasClass("navLinkSection")) {
            let section = $('#' + tThis.data("section"));
            Nav.goToSection(section, navTimelineClose);
            return false;
        }
    });

    $(".js-goToSection").on("click", function(e) {
        let section = $('#' + $(this).data("section"));
        Nav.goToSectionAlone(section);
        return false;
    });



    // SLIDERS
    if ($(".js-slideExpertises").length > 0) {
        new Splide('.js-slideExpertises', {
            type: 'loop',
            perPage: 2,
            gap: 10,
            pagination: false,
            breakpoints: {
                992: {
                    perPage: 1,
                },
                768: {
                    perPage: 2,
                },
                580: {
                    perPage: 1,
                }
            }
        }).mount();
    }


    if ($(".js-slideTem").length > 0) {
        new Splide('.js-slideTem', {
            type: 'loop',
            perPage: 3,
            gap: 30,
            pagination: false,
            breakpoints: {
                992: {
                    perPage: 2,
                },
                768: {
                    perPage: 1,
                }
            }
        }).mount();
    }


    $(".js-openTem").on("click", function(e) {
        let tThis = $(this);
        let details = $("#" + tThis.data("details"));
        let detailsId = tThis.data("details");

        $(".temoignages").slideUp(400, function() {
            tThis.attr("aria-expanded", true).addClass("opened");
            openTem(details, detailsId);
        });
    });


    $(".js-closeDetailsTem").on("click", function(e) {
        e.stopImmediatePropagation();
        let detailsToHide = $(".temDetails.visible");
        let openedButton = $(".js-openTem.opened");
        let detId = detailsToHide.attr("id");

        //lancer HideDetails
        let hideDetailsT = Tem.hideDetails(detailsToHide, detId);
        hideDetailsT.play();
        hideDetailsT.finished.then(function() {
            detailsToHide.slideUp(400, function() {
                detailsToHide.attr("aria-hidden", true).removeClass("visible");
                openedButton.attr("aria-expanded", false).removeClass("opened");
                $(".temoignages").slideDown(400, function() {
                    //scroll
                    let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
                    let eTop = parseInt($(".temoignages").offset().top) - 64;
                    anime({
                        targets: scrollElement,
                        scrollTop: eTop,
                        duration: 350,
                        easing: 'cubicBezier(.215,.61,.355,1)'
                    });
                });
            });
        });
        return false;
    });

    // EXAMPLES
    if (device.desktop()) { var hideComp = Comp.closeComp(); }


    $(".js-openComp").on("click", function(e) {
        let tThis = $(this);
        let details = $("#" + tThis.data("details"));
        let detailsId = tThis.data("details");


        // VERSION ORDI
        if (device.desktop()) {
            hideComp.play();
            hideComp.finished.then(function() {
                // open Details
                tThis.attr("aria-expanded", true).addClass("opened");
                openDet(details, detailsId);
            });
        } else {
            //VERSION MOBILE
            $(".competences__liste__item").slideUp(400, function() {
                tThis.attr("aria-expanded", true).addClass("opened");
                openDet(details, detailsId);
            });

        }

        return false;
    });

    $(".js-closeDetails").on("click", function(e) {
        e.stopImmediatePropagation();
        let detailsToHide = $(".compDetails.visible");
        let openedButton = $(".js-openComp.opened");
        let detId = detailsToHide.attr("id");

        //lancer HideDetails
        let hideDetails = Comp.hideDetails(detailsToHide, detId);
        hideDetails.play();
        hideDetails.finished.then(function() {

            detailsToHide.slideUp(400, function() {
                detailsToHide.attr("aria-hidden", true).removeClass("visible");
                openedButton.attr("aria-expanded", false).removeClass("opened");

                if (device.desktop()) {
                    //openComp
                    let showComp = Comp.openComp();
                    showComp.play();
                    showComp.finished.then(function() {
                        //scroll
                        let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
                        let eTop = parseInt($(".competences").offset().top) - 64;
                        anime({
                            targets: scrollElement,
                            scrollTop: eTop,
                            duration: 350,
                            easing: 'cubicBezier(.215,.61,.355,1)'
                        });
                    });
                } else {
                    $(".competences__liste__item").slideDown(400, function() {
                        //scroll
                        let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
                        let eTop = parseInt($(".competences").offset().top) - 64;
                        anime({
                            targets: scrollElement,
                            scrollTop: eTop,
                            duration: 350,
                            easing: 'cubicBezier(.215,.61,.355,1)'
                        });
                    });
                }

            });

        });
        return false;
    });



    // THEMATIQUES
    $(".js-openTheme").on("click", function(e) {
        e.stopImmediatePropagation();
        let button = $(this);
        let block = button.parent().parent();
        let toDeploy = block.find(".js-toDeploy");

        if ($(".theme.deployed").length > 0) {
            let toClose = $(".theme.deployed .js-toDeploy");
            toDeploy.slideDown();
            block.addClass("deployed");

            toClose.slideUp(400, function() {
                toClose.parent().parent().removeClass("deployed");
            });
        } else {
            toDeploy.slideDown();
            block.addClass("deployed");
        }

        return false;
    });


});


function openDet(details, detailsId) {
    details.slideDown(400, function() {
        details.attr("aria-hidden", false).addClass("visible");

        // Scroll to section
        let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
        let eTop = parseInt(details.offset().top) - 64;
        anime({
            targets: scrollElement,
            scrollTop: eTop,
            duration: 350,
            easing: 'cubicBezier(.215,.61,.355,1)',
            complete: function() {
                // lancer showDetails
                let showD = Comp.showDetails(details, detailsId);
                showD.play();
            }
        });
    });
}


function openTem(details, detailsId) {
    details.slideDown(400, function() {
        details.attr("aria-hidden", false).addClass("visible");
        // Scroll to section
        let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
        let eTop = parseInt(details.offset().top) - 64;
        anime({
            targets: scrollElement,
            scrollTop: eTop,
            duration: 350,
            easing: 'cubicBezier(.215,.61,.355,1)',
            complete: function() {
                // lancer showDetails
                let showT = Tem.showDetails(details, detailsId);
                showT.play();
            }
        });
    });
}

function ieVersion(uaString) {
    uaString = uaString || navigator.userAgent;
    var match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
    if (match) return parseInt(match[2])
}
import anime from 'animejs/lib/anime.es.js';

export function closeComp() {
    let closeComp = anime.timeline({
            autoplay: false
        })
        .add({
            targets: '.competences__liste__item',
            translateY: [0, '-100%'],
            duration: 400,
            delay: anime.stagger(80),
            easing: 'cubicBezier(.215,.61,.355,1)',
            complete:function(){
                $(".competences__liste").slideUp();
            }
        });

    return closeComp;
}



export function openComp() {
    let openComp = anime.timeline({
            autoplay: false,
            begin:function(){
                $(".competences__liste").slideDown();
            }
        })
        .add({
            targets: '.competences__liste__item',
            translateY: ['-100%', 0],
            duration: 400,
            delay: anime.stagger(80),
            easing: 'cubicBezier(.215,.61,.355,1)'
        });

    return openComp;
}


export function showDetails(details, detailsId) {
    let showD = anime.timeline({
            autoplay: false
        })
        .add({
            targets: "#" + detailsId + ' .compDetails__Close',
            opacity: [0, 1],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)'
        })
        .add({
            targets: "#" + detailsId + " .compDetails__header__pic svg",
            scale: [0.5, 1],
            opacity: [0, 1],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)'
        })
        .add({
            targets: "#" + detailsId + " .compDetails__header__title",
            opacity: [0, 1],
            translateY: [20, 0],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)'
        }, "-=200")
        .add({
            targets: "#" + detailsId + " .compDetails__item",
            opacity: [0, 1],
            translateY: [20, 0],
            duration: 500,
            delay: anime.stagger(100),
            easing: 'cubicBezier(.215,.61,.355,1)'
        }, "-=100");
    return showD;
}

export function hideDetails(details, detailsId) {
    let hideD = anime.timeline({
            autoplay: false
        })
        .add({
            targets: "#" + detailsId + ' .compDetails__Close',
            opacity: [1, 0],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)'
        })
        .add({
            targets: "#" + detailsId + " .compDetails__header__pic svg",
            scale: [1, 0.5],
            opacity: [1, 0],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)'
        })
        .add({
            targets: "#" + detailsId + " .compDetails__header__title",
            opacity: [1, 0],
            translateY: [0, 20],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)'
        }, "-=200")
        .add({
            targets: "#" + detailsId + " .compDetails__item",
            opacity: [1, 0],
            translateY: [0, 20],
            duration: 500,
            delay: anime.stagger(100),
            easing: 'cubicBezier(.215,.61,.355,1)'
        }, "-=100");

    return hideD;
}